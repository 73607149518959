.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: black;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
body{
  overflow: hidden;
}

.camion{
  border-radius: 64px 64px 64px 64px;
  -moz-border-radius: 64px 64px 64px 64px;
  -webkit-border-radius: 100px 100px 100px 100px;
  border: 0px solid #000000;
}

.add-button {
  background-color: #cbd300 !important;
  border: 1px solid #cbd300 !important;
  border-radius: 3px;
  color: white !important;
  font-variant-caps: all-petite-caps !important;
  font-weight: bold !important;
}

.add-input :focus {
  border-color: #cbd300 !important;
  box-shadow: 0 0 0 0.1rem #cbd300 !important;
}

.barra {
  border-top: 3px solid #cbd300;
}

.component {
  text-align: center;
  height: max-content; 
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.square-item {
  display: inline-block;
  text-align: center;
  flex-direction: row;
}

.item {
  line-height: 20px;
  margin: 0px !important;
}

.axis-x .item {
  display: inline-block;
  line-height: 20px;
  padding: 0;
  text-align: center;
  width: 150px;
}

.axis-x .component {
  white-space: nowrap;
}

.option-selected {
  color: #cbd300;
  font-weight: bold;
}

.resaltar:hover{
  cursor: pointer;
}

.option-selected:hover {
  color: #cbd300;
  font-weight: bold;
}

.loading {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  display: block;
  opacity: 0.8;
  background-color: hsl(0, 4%, 24%);
  z-index: 99;
  text-align: center;
}

