$theme-colors: (
        "falabella": #cbd300,
        "dark": #231f20,
        "gray": #828282,
);
$grid-breakpoints: (
  // Extra small screen / phone
        xs: 0,
  // Small screen / phone
        sm: 576px,
  // Medium screen / tablet
        md: 768px,
  // Large screen / desktop
        lg: 992px,
  // Extra large screen / wide desktop
        xl: 1500px
);
$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px
);
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: #cbd300;
  background-image: -webkit-linear-gradient(45deg,
          rgba(255, 255, 255, .2) 25%,
          transparent 25%,
          transparent 50%,
          rgba(255, 255, 255, .2) 50%,
          rgba(255, 255, 255, .2) 75%,
          transparent 75%,
          transparent);
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background: none;
}

::placeholder {
  font-style: italic;
}

[role=button] {
  cursor: pointer;
}
@import "~bootstrap/scss/bootstrap.scss";
@import "~noty/src/noty.scss";
@import "~noty/src/themes/bootstrap-v4.scss";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
//@import "./components/sidebar/sidebar.scss";