html,body {
    height: 100%;
    width: 100%;
    background-color: #ebebeb;
}

body.my-login-page {
    background-color: #f7f9fb;
    font-size: 14px;
}

.my-login-page .brand {
    width: 90px;
    height: 90px;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto;
    margin: 40px auto;
    box-shadow: 0 4px 8px rgba(0,0,0,.05);
    position: relative;
    z-index: 1;
}

.my-login-page .brand img {
    width: 100%;
}

.my-login-page .logo img {
    width: 100%;
}

.my-login-page .card-wrapper {
    width: 400px;
}

.my-login-page .card {
    border-color: transparent;
    box-shadow: 0 4px 8px rgba(0,0,0,.05);
}

.my-login-page .card.fat {
    padding: 10px;
}

.my-login-page .card .card-title {
    margin-bottom: 30px;
}

.my-login-page .form-control {
    border-width: 2.3px;
}

.my-login-page .form-group label {
    width: 100%;
}

.my-login-page .btn.btn-block {
    padding: 12px 10px;
}

.my-login-page .footer {
    margin: 30px 0;
    color: #888;
    text-align: center;
}

@media screen and (max-width: 425px) {
    .my-login-page .card-wrapper {
        width: 90%;
        margin: 0 auto;
    }
}

@media screen and (max-width: 320px) {
    .my-login-page .card.fat {
        padding: 0;
    }

    .my-login-page .card.fat .card-body {
        padding: 15px;
    }
}

.bg {
    background-image: url("./barra/profacol1.png");
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    }



.fa{
    height: initial;
}