.main-sidebar {
    z-index: 1070;
    transition: all .25s;
    height: calc(100vh - 60px);
    white-space: nowrap;
    overflow-x: hidden !important;
    width: 300px;
}

@media (max-width: 767.98px) {
    .main-sidebar {
        position:fixed;
        width: 100%;
    }
}

@media (max-width: 575.98px) {
    .main-sidebar {
        position:fixed;
        width: 100%;
    }
}

.main-sidebar.w-0 {
    width:0;
    overflow: hidden !important;
    transition: .25s
}

.fa {
    &.inactive {
        transition: opacity .3s, transform .3s;
        transform: rotate(-180deg) scale(.5);
        opacity: 0;

    }
    &.active {
        transition: opacity .3s, transform .3s;
        transform: rotate(-180deg) scale(1);
        opacity: 1;
    }
    &.inactive.collapsed {
        transform: rotate(0deg) scale(1);
        opacity: 1;
    }
    &.active.collapsed {
        transform: rotate(180deg) scale(.5);
        opacity: 0;
    }
}
